import React from "react";
import { useData } from "../contexts/DataContext";

const Card = ({ card, onClick }) => {
  const { data } = useData();

  const cardSize = {
    width: "min(90vw, 60vh)",
    height: "min(120vw, 80vh)",
  };

  const containerStyle = {
    ...cardSize,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  const suitSymbols = {
    Acorns: "🌰",
    Bells: "🔔",
    Hearts: "❤️",
    Leaves: "🍀",
  };

  const getRankSymbols = () => {
    const symbol = suitSymbols[card.suit];
    switch (card.rank) {
      case "7":
        return Array(7).fill(symbol).join(" ");
      case "8":
        return Array(8).fill(symbol).join(" ");
      case "9":
        return Array(9).fill(symbol).join(" ");
      case "10":
        return Array(10).fill(symbol).join(" ");
      case "J":
        return `🤴 ${symbol}`;
      case "Q":
        return `👸 ${symbol}`;
      case "K":
        return `👑 ${symbol}`;
      case "A":
        return `🃏 ${symbol}`;
      default:
        return symbol;
    }
  };

  return (
    <div
      className="relative bg-white dark:bg-gray-800 border-2 border-black rounded-3xl p-4 cursor-pointer shadow-lg select-none"
      style={containerStyle}
      onClick={onClick}
    >
      <div className="font-bold text-gray-800 dark:text-gray-200 text-center">
        {card && (
          <div className="text-4xl">
            {/* Top left corner */}
            <div className="absolute top-4 left-4 flex flex-col pl-2 pt-2">
              <div className="space-y-2">
                <p>{card.rank}</p>
                <p>{suitSymbols[card.suit]}</p>
              </div>
            </div>

            {/* Bottom right corner */}
            <div className="absolute bottom-4 right-4 flex flex-col pr-2 pb-2">
              <div className="space-y-1">
                <p>{card.rank}</p>
                <p>{suitSymbols[card.suit]}</p>
              </div>
            </div>
          </div>
        )}

        {/* Center artwork */}
        <div className="leading-normal text-5xl md:leading-normal md:text-6xl">
          <div className="flex items-center justify-center h-full p-2">
            {card ? getRankSymbols(card.rank) : data.setup.pressToStart}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
