import React from "react";
import { useData } from "./contexts/DataContext";
import Deck from "./components/Deck";

function App() {
  const { loading } = useData();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
        <div className="w-16 h-16 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center bg-gray-100 dark:bg-gray-900 p-4 h-dvh overflow-hidden">
      <Deck />
    </div>
  );
}

export default App;
