import React, { useState, useEffect } from "react";
import { useData } from "../contexts/DataContext";
import Card from "./Card";

const Deck = () => {
  const { data } = useData();

  const [isScreenBigEnough, setIsScreenBigEnough] = useState(true);
  const [isDeviceHorizontal, setIsDeviceHorizontal] = useState(true);

  const suits = ["Acorns", "Bells", "Hearts", "Leaves"];
  const ranks = ["7", "8", "9", "10", "J", "Q", "K", "A"];

  // Generate the full deck
  const initialDeck = suits.flatMap((suit) =>
    ranks.map((rank) => ({ rank, suit }))
  );

  const [deck, setDeck] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);

  useEffect(() => {
    shuffleDeck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shuffleDeck = () => {
    const shuffledDeck = [...initialDeck];
    for (let i = shuffledDeck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledDeck[i], shuffledDeck[j]] = [shuffledDeck[j], shuffledDeck[i]];
    }
    setCurrentCard(null);
    setDeck(shuffledDeck);
  };

  const drawCard = () => {
    if (deck.length > 0) {
      const [nextCard, ...remainingDeck] = deck;
      setCurrentCard(nextCard);
      setDeck(remainingDeck);
    }
  };

  useEffect(() => {
    const checkScreenHeight = () => {
      const minHeight = 600;

      setIsScreenBigEnough(
        window.innerWidth >= minHeight / 2 && window.innerHeight >= minHeight
      );

      setIsDeviceHorizontal(window.innerWidth > window.innerHeight);
    };

    checkScreenHeight();

    window.addEventListener("resize", checkScreenHeight);

    return () => {
      window.removeEventListener("resize", checkScreenHeight);
    };
  }, []);

  if (!isScreenBigEnough) {
    return (
      <div className="text-center space-y-4 text-black dark:text-gray-200">
        <h2 className="text-3xl">{data.errors.screenTooSmall}</h2>
        {isDeviceHorizontal && (
          <p className="text-xl">{data.errors.rotateScreen}</p>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center text-black dark:text-gray-200">
      <h1 className="mb-4 text-2xl font-bold">{data.title}</h1>

      <p className="mb-2 text-lg">
        {data.setup.cardsRemaining}
        {deck.length}
      </p>

      <Card card={currentCard} onClick={drawCard} />

      <p className="mt-4 text-2xl">
        {currentCard ? data.rules[currentCard.rank] : data.setup.welcome}
      </p>

      <button
        onClick={shuffleDeck}
        className="mt-4 py-2 px-4 rounded-lg text-white font-semibold bg-blue-500 hover:opacity-80 transition duration-200 disabled:opacity-0"
        disabled={!currentCard}
      >
        {data.setup.reset}
      </button>
    </div>
  );
};

export default Deck;
