import React, { createContext, useContext, useEffect, useState } from "react";
import { useLanguageStore } from "../stores/useLanguageStore";

const DataContext = createContext(undefined);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { language } = useLanguageStore();

  useEffect(() => {
    // Fetch data from the public folder
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/data/${language}.json`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [language]);

  return (
    <DataContext.Provider value={{ data, loading }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use data context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
